(function() {


    document.querySelectorAll('.c-tabs__tabs a').forEach((el)=>{

        if(el.parentElement.classList.contains('active')){

            if(el.getAttribute('data-target') == 'tab01'){
                document.body.classList.remove('is-company');
                document.body.classList.add('is-client');
            } else {
                document.body.classList.add('is-company');
                document.body.classList.remove('is-client');
            }

        }

        el.addEventListener('click',(e)=>{

            document.querySelectorAll('.c-tabs__tabs li.active').forEach((tab)=>{
                tab.classList.remove('active');
            });
            document.querySelectorAll('.c-tabs__contents > div').forEach((tab)=>{
                tab.classList.remove('active');
            });

            el.parentElement.classList.add('active');
            const content = document.getElementById(el.getAttribute('data-target'));

            content.classList.add('active');
            if(el.getAttribute('data-target') == 'tab02'){
                content.parentElement.classList.add('o-bg-color--yellow');
                document.body.classList.add('is-company');
                document.body.classList.remove('is-client');
            } else {
                content.parentElement.classList.remove('o-bg-color--yellow');
                document.body.classList.remove('is-company');
                document.body.classList.add('is-client');
            }

            e.preventDefault();
            e.stopPropagation();
        })
    });
})();

