

export default function (message = '') {
    const el = document.createElement('div');
    el.classList.add('c-popup-message');

    el.innerHTML = `
        <button type="button">x</button>
        <div class="c-popup-message__msg">${message}</div>
    `;

    el.addEventListener('click',()=>{
        el.remove();
    })

    document.body.append(el);
}
