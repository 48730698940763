
document.addEventListener('DOMContentLoaded',function() {

   document.querySelectorAll('.os-scroll-to').forEach((el=>{
       el.addEventListener('click',(e)=>{
           const target = el.getAttribute('data-target');

           if(target && document.getElementById(target)){

               if(target == 'jestem-firma'){
                   document.getElementById("companyBtn").click();
               }
               if(target == 'jestem-tworca'){
                   document.getElementById("clientBtn").click();
               }

               document.getElementById(target).scrollIntoView();
           }
           else if(target == 'pakiety'){
               if(document.body.classList.contains('is-client')){
                   document.getElementById('pakiety1').scrollIntoView();
               }
               else {
                   document.getElementById('pakiety2').scrollIntoView();
               }
           }
           e.stopPropagation();
           e.preventDefault();
       })
   }));

   switch (window.location.hash){
       default:
           if(document.getElementById(window.location.hash.replace('#',''))){
               document.getElementById(window.location.hash.replace('#','')).scrollIntoView();
           }
   }

});
