import axios, * as others from 'axios';
import formFieldState from "./form-field-state";
import popupMsg from "./popup-message";

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementById('frm');
    // popupMsg('Formularz został wysłany. Wkrótce skontaktuje sie z Państwem nasz konsultant.');

    const inputEl = el.querySelectorAll('input, textarea');

    const refreshCaptcha = function(){
        window.grecaptcha.reset();
    }

    if (inputEl.length) {
        inputEl.forEach((field) => {
            field.addEventListener('focus',e=>{
                field.parentElement.classList.remove('o-field--error')
            })
        });
    }

    el.addEventListener('submit',(e)=>{

        submitForm(el);

        e.stopPropagation();
        e.preventDefault();
    })

    /**
     * wyslij caly formularz
     * @param form
     */
    function submitForm(form) {
        const postData = {};
        const elInput = form.querySelectorAll('input, textarea');
        if (elInput) {
            elInput.forEach((field) => {
                let name = field.getAttribute('name');
                if(field.getAttribute("type") == "checkbox")
                    postData[name] = field.checked;
                else
                    postData[name] = field.value;
            });
        }
        addLoading(form);
        form.classList.add('loading');


        axios.post(form.getAttribute('action'), postData)
            .then((response) => {
                form.classList.remove('loading');
                removeLoading(form);
                popupMsg(response.data);
                resetForm(form);


            })
            .catch((error) => {
                form.classList.remove('loading');
                removeLoading(form);
                const data = error.response;
                if (data.status == 422) {
                    if (data.data) {
                        for (let index in postData) {
                            if (data.data[index]) {
                                const field = form.querySelector('input[name="' + index + '"], textarea[name="' + index + '"]');
                                if (field) {
                                    formFieldState(field).setErrorState(data.data[index]);
                                }

                                if(index == 'g-recaptcha-response'){
                                    document.querySelector('.o-captcha-field__msg').innerHTML = data.data[index];
                                    setTimeout(function(){
                                        document.querySelector('.o-captcha-field__msg').innerHTML = '';
                                    }, 3000);
                                }
                            }

                        }
                    }
                } else alert(data.data.message + 'Problem z wysyłką formularza. Proszę spróbować odświeżyć stronę.');

            });
    }

    /**
     * wyczysc formularz
     * @param form
     */
    function resetForm(form) {
        const elInput = form.querySelectorAll('input, textarea');
        if (elInput) {
            elInput.forEach((field) => {

                if(field.getAttribute("type") == "checkbox") {
                    field.checked = false;
                    field.parentElement.classList.remove('checkbox--error');
                    field.parentElement.querySelector('.checkbox__error').innerHTML = '';
                }
                else if(field.getAttribute("type") != "hidden")
                    field.value = '';

            });
        }

        refreshCaptcha();
    }
    /**
     * dodaj loading
     * @param form
     */
    function addLoading(form) {
        const el = document.createElement('div');
        el.classList.add('lds-spinner');
        el.classList.add('lds-spinner--center');
        el.classList.add('os-loading');
        for(let i = 0; i < 13; i++){
            el.innerHTML += "<div></div>";
        }
        const wrapperEl = form.querySelector('.os-wrapper');
        if(wrapperEl){
            wrapperEl.append(el);
        }
        else
            form.append(el);
    }

    /**
     * usun loading
     * @param form
     */
    function removeLoading(form) {
        const el = form.querySelector('.os-loading');
        if(el){
            el.remove();
        }
    }

});
