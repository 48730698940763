import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', function() {

    const el = document.getElementById('cookies');
    const scripts = document.querySelectorAll('script[type="text/plain"]');

    const drawInlineScript = function (content){
        const scriptEl = document.createElement('script');
        scriptEl.type = 'text/javascript';
        scriptEl.textContent = content;
        document.body.append(scriptEl);
    }

    const drawHtml = function (content){
        const htmlEl = document.createElement('div');
        htmlEl.innerHTML = content;
        document.body.append(htmlEl);
    }

    const initScript = function() {

        scripts.forEach((script) => {
            const type = script.getAttribute('data-type');

            if (type == "js") {
                drawInlineScript(script.innerHTML);
                script.remove();
            } else if (type == 'html') {
                drawHtml(script.innerHTML);
                script.remove();
            }

        });
    }

    const init = function() {

        const accept = el.getElementsByClassName('js-accept')[0];
        const reject = el.getElementsByClassName('js-reject')[0];

        Cookies.get('easypromo') != 1 && Cookies.get('easypromo') != 2 ? el.classList.remove('is-hidden') : false;

        if(Cookies.get('easypromo') == 1)
            initScript();

        accept.addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set("easypromo", 1, { expires: 356, path: '/' });
            el.classList.add('is-hidden');
            initScript();
        });

        reject.addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set("easypromo", 2, { expires: 356, path: '/' });
            el.classList.add('is-hidden');
        });
    };


    el ? init() : false;

}, false);
