(function() {
    document.querySelectorAll('.os-packages01 .c-package').forEach((el)=>{
        el.addEventListener('mouseover',(e)=>{
            document.querySelectorAll('.os-packages01 .c-package').forEach((x)=>{
                x.classList.remove('active');
            });
            el.classList.add('active');
        })
    })
})();

