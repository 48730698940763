import Swiper, { Autoplay, Controller, EffectCoverflow, Keyboard, Lazy, Navigation, Pagination, Thumbs, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([ Autoplay, Controller, EffectCoverflow, Keyboard, Lazy, Navigation, Pagination, Thumbs, Scrollbar ]);

document.addEventListener('DOMContentLoaded', function() {

    const swiper01 = document.getElementsByClassName('js-swiper01')[0];
    const swiper02 = document.getElementsByClassName('js-swiper02')[0];
    const package01 = document.getElementsByClassName('js-swiper-package01')[0];
    const package02 = document.getElementsByClassName('js-swiper-package02')[0];

    window.swiper01Carousel = function() {
        const swiper = new Swiper(swiper01, {
            breakpoints: {
                501: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                }
            },
            loop: true,
            navigation: {
                nextEl: '.c-slider .swiper-button-next',
                prevEl: '.c-slider .swiper-button-prev',
            },
            pagination: {
                el: '.c-slider .swiper-pagination',
                clickable: true
            },
            scrollbar: {
                el: '.c-slider .swiper-scrollbar',
                draggable: true,
                dragSize: 50,
                hide: false
            },

            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
        });
    };

    window.swiper02Carousel = function() {
        const swiper = new Swiper(swiper02, {
            breakpoints: {
                501: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                }
            },
            loop: true,
            freeMode: true,
            navigation: {
                nextEl: '.c-slider-company .swiper-button-next',
                prevEl: '.c-slider-company .swiper-button-prev',
            },
            pagination: {
                el: '.c-slider-company .swiper-pagination',
                clickable: true
            },
            scrollbar: {
                el: '.c-slider-company .swiper-scrollbar',
                draggable: true,
                dragSize: 50,
                hide: false
            },
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
        });
    };

    window.package01Carousel = function() {
        const swiper = new Swiper(package01, {
            breakpoints: {
                501: {
                    slidesPerView: 1,
                },
                700: {
                    slidesPerView: 2,
                },
                1040: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                }
            },
            loop: false,
            freeMode: true,
            navigation: {
            },
            pagination: {
                el: '.os-packages01 .swiper-pagination',
                clickable: true
            },
            scrollbar: {
                el: '.os-packages01 .swiper-scrollbar',
                draggable: true,
                dragSize: 50,
                hide: false
            },
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
        });
    };

    window.package02Carousel = function() {
        const swiper = new Swiper(package02, {
            breakpoints: {
                501: {
                    slidesPerView: 1,
                },
                700: {
                    slidesPerView: 2,
                },
                1040: {
                    spaceBetween: 30,
                    slidesPerView: 3,
                }
            },
            loop: false,
            freeMode: true,
            navigation: {
            },
            pagination: {
                el: '.os-packages02 .swiper-pagination',
                clickable: true
            },
            scrollbar: {
                el: '.os-packages02 .swiper-scrollbar',
                draggable: true,
                dragSize: 50,
                hide: false
            },
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 800,
        });
    };


    window.carousels = function() {
        swiper01 ? swiper01Carousel() : false;
        swiper02 ? swiper02Carousel() : false;
        package01 ? package01Carousel() : false;
        package02 ? package02Carousel() : false;
    }

}, false)
