
document.addEventListener('DOMContentLoaded',function() {

    let current = 1;

    setInterval(()=>{

        for(let i=1;i<=4;i++){
            document.getElementById('logo-layer-0' + i).style.display = "none";
        }
        document.getElementById('logo-layer-0' + current).style.display = "block";

        current++;
        if(current > 4){
            current = 1;
        }

    },300);

});
