export default function (field) {

    const baseClass = 'o-field';
    const parentEl = field.parentElement;
    const errorEl = parentEl.querySelector('.'+baseClass+'__msg');

    const setValidState = function(){
        parentEl.classList.remove(baseClass+'--error');
        parentEl.classList.remove('animate__animated');
        parentEl.classList.remove('animate__shakeX');

        removeErrorMsg();
    }

    function removeErrorMsg(){
        if(errorEl){
            errorEl.innerHTML='';
        }

    }

    const focusEvent = function(){
        setValidState();
    }

    const renderMsg = function(msg){
        if(errorEl)
            errorEl.innerHTML = msg;
    }

    var setErrorState = function(msg = null){
        removeErrorMsg();

        parentEl.classList.add(baseClass + '--error');
        parentEl.classList.add('animate__animated');
        parentEl.classList.add('animate__shakeX');

        if(msg){
            renderMsg(msg);
        }

        // el.addEventListener('focus',focusEvent,{ once: true });
    }

    return {
        setErrorState,
        setValidState
    }
}
