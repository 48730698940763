(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['Montserrat:wght@400;500;600;700:latin-ext']
        }
    });
})();

