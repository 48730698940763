import css from './sass/style.scss';
import 'animate.css';

require('./js/fonts.js');
require('./js/tab.js');
require('./js/packages.js');
require('./js/carousels.js');
require('./js/scroll-to.js');
require('./js/logo.js');
require('./js/anims.js');
require('./js/marquee.js');
require('./js/menu.js');
require('./js/form.js');
require('./js/init.js');
require('./js/cookies.js');
