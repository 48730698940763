
document.addEventListener('DOMContentLoaded',function() {



    document.documentElement.removeAttribute('style');


    const init = function() {
        document.documentElement.classList.add('is-loaded');

        window.carousels();

        setTimeout(function() {

            history.pushState("", document.title, window.location.pathname + window.location.search);
        }, 250);
    };

    window.addEventListener('load', init);

}, false);
