document.addEventListener('DOMContentLoaded',function() {

   document.getElementById('menu-btn').addEventListener('click',function (){
      document.body.classList.toggle('is-menu');
   });

   document.querySelectorAll('.c-menu a').forEach((el)=>{
        el.addEventListener('click',e=>{
            document.body.classList.remove('is-menu');
        })
   });

});
