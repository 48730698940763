import inView from 'in-view';


document.addEventListener('DOMContentLoaded', function() {

    inView('.os-animate')
        .on('enter', (e)=>{
            if(e.getAttribute('data-delay')){
                setTimeout(()=>{
                    e.classList.add('animate__animated');
                    e.classList.add('animate__' + e.getAttribute('data-anim'));
                },e.getAttribute('data-delay'))
            } else {
                e.classList.add('animate__animated');
                e.classList.add('animate__' + e.getAttribute('data-anim'));
            }
        })

});
